import React from "react"

const StandardTitle = ({
  title,
  subTitle,
  addClassTitle = "",
  addClassSubTitle = "",
}) => {
  return (
    <>
      <h2 className={"ta-c cg-7 ff-gb fs-34 lh-48 " + addClassTitle}>
        {title}
      </h2>
      {subTitle && (
        <h3 className={"fs-16 cg-7 ff-gm lh-24 ta-c mb-4 " + addClassSubTitle}>
          {subTitle}
        </h3>
      )}
    </>
  )
}

export default StandardTitle
